@import url("https://use.typekit.net/ecr1coa.css");

.inputForm {
  margin-top: 0;
  width: 100%;
}

.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
}

.inputField label {
  font-size: 12px;
  margin-bottom: 10px;
  margin: 0;
  font-weight: 600;
  color: #131f67;
}

.messageFieldForm label {
  font-size: 12px;
  margin-bottom: 10px;
  margin: 0;
  color: #131f67;
  font-weight: 600;
  display: none;
}

.inputField input {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  font-style: normal;
  border: none;
  border-bottom: 1.5px solid #131f67;
  /* padding: 0; */
  padding: 0px 0px 5px 0px;

  color: #131f67;
}

.inputField input::placeholder {
  /* padding: 0px 0px 5px 20px; */
  font-size: 0.8rem;
}

.lastName {
  font-family: "sweet-sans-pro", sans-serif;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  border: none !important;
  border-bottom: 1.5px solid #131f67 !important;
  /* padding: 0px !important; */
  padding: 0px 0px 5px 0px !important;

  color: #131f67 !important;
  background-color: transparent !important;
  border-radius: 0% !important;
  text-indent: 5% !important;
}

.lastName::placeholder {
  font-size: 0.8rem;
  color: #131f67;
  text-indent: 5%;
  font-family: "sweet-sans-pro", sans-serif;
  font-style: normal !important;
  font-weight: 600 !important;
}

.messageFieldForm {
  grid-column: 1 / -1;
}

.messageFieldForm textarea {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1.5px solid #131f67;
  margin-bottom: 15px;
  resize: none;
  width: 100%;
  font-size: 1rem;
  color: #131f67;

  height: 80px;
  /* text-indent: 20px; */
  font-size: 1rem;
  padding-top: 10px;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-appearance: none;
  border-radius: 0;
}

.messageFieldForm textarea::placeholder {
  -webkit-appearance: none;
  border-radius: 0;
  color: #131f67;

  margin-left: 10px;
  text-indent: 0px;

  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0px 0px 5px 0px;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submitButton {
  background-color: #f6785c;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1.9rem;
  text-decoration: none;
  margin-top: 0;
}

@import url("https://use.typekit.net/ecr1coa.css");

/* HERO */

/* .hero {
  display: flex;
} */

.hero {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
}
.heroContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-bottom: 2rem;
}

.hero-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.hero-text h1 {
  font-size: 4.1rem;

  line-height: 1.2;
  /* margin-bottom: 1rem; */
  font-family: "minerva-modern", sans-serif;
  font-weight: normal;
  font-style: normal;
  text-align: left;
}

.hero-text h2 {
  font-size: 4.1rem;

  line-height: 1.2;
  /* margin-bottom: 1rem; */
  font-family: "minerva-modern", sans-serif;
  font-weight: normal;
  font-style: normal;
  text-align: left;
}

.hero-text p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: initial;
}

.hero-text button {
  background-color: #f6785c;
  color: #fff;
  border: none;
  padding: 12px 49px;
  border-radius: 25px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hero-text button img {
  /* margin-left: 1rem; */
  position: relative;
  left: 28px;
  width: 12%;
  top: 1px;
}

.hero-text button:hover {
  background-color: #ff9d89;
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroimageSize {
  width: 30rem;

  position: relative;
  right: -9%;
  z-index: -1;
}

.hero-image-mobile {
  display: none;
}

@media screen and (max-width: 810px) {
  .hero.show {
    margin-top: calc(60vh - 100px);
    animation: slide-down 0.3s;
  }

  .hero.hide {
    margin-top: 8%;
    animation: slide-up-hero 0.3s;
  }

  @keyframes slide-up-hero {
    from {
      transform: translateY(30%);
    }
    to {
      transform: translateY(0);
    }
  }
}
@media screen and (max-width: 850px) {
  .heroimageSize {
    width: 46vw;
  }
}

@media screen and (max-width: 595px) {
  .hero-image {
    display: none;
  }

  .heroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .hero-text {
    width: 90%;
  }

  .hero-image-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
  }
  .heroimageSizeMobile {
    width: 21rem;
  }
}
@media screen and (max-width: 515px) {
  .hero.show {
    margin-top: 67%;

    animation: slide-down 0.3s;
  }
}

@import url("https://use.typekit.net/ecr1coa.css");

.inputField input {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 10px;
}

.inputField input::placeholder {
  font-size: 1rem;
  color: #131f67;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@import url("https://use.typekit.net/ecr1coa.css");

.quoteSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  justify-content: center;
  width: auto;
  z-index: 1;
}

.quoteText {
  font-size: 1.25rem;
  top: 15%;
  max-width: 650px;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.quoteSection img {
  position: absolute;
  max-width: 900px;
  height: auto;
}

.staticQuotesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.quote-author {
  font-size: 1.4rem;
  color: #131f67;
  font-family: sans-serif;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.quoteImg {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  /* position: absolute; */
  z-index: -1;
}

@media only screen and (max-width: 900px) {
  .quoteText {
    font-size: 1.1rem;
    padding: 25px;
    top: 15%;
    max-width: 600px;
  }

  .quote-author {
    font-size: 1rem;
    color: #131f67;
    font-family: sans-serif;
    font-family: "sweet-sans-pro", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
}

@media only screen and (max-width: 700px) {
  .quoteText {
    font-size: 0.9rem;
    padding: 25px;
    top: 15%;
    max-width: 600px;
  }

  .quote-author {
    font-size: 1rem;
    color: #131f67;
    font-family: sans-serif;
    font-family: "sweet-sans-pro", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
}
